<template>
  <div>
    <div class="d-flex">
      <div class="ml-6">
        <px-oferta-foto max-height="80"
                        max-width="80"
                        class="d-flex align-center justify-center"
                        :foto="itemAvaliacao.oferta.oferta.foto"/>
      </div>
      <div class="ml-5 d-flex flex-column">
        <div class="d-flex align-start justify-center flex-column title-oferta-foto">
          <router-link target="_blank"
                       :to="`/oferta/${itemAvaliacao.oferta.oferta.id}`"
                       class="body-1">
            {{ itemAvaliacao.oferta.tituloProduto }}
          </router-link>
          <div>
            <px-show-variacoes-completo v-if="itemAvaliacao.oferta.variacoes"
                                        :variacoes="itemAvaliacao.oferta.variacoes"/>
          </div>
        </div>
        <div class="full-width">
          <v-row dense>
            <v-col cols="12" class="font-weight-bold">
              Avaliação geral:
            </v-col>
            <v-col cols="12">
              <v-rating
                hover
                background-color="grey darken-1"
                length="5"
                size="30"
                v-model="itemAvaliacao.avaliacao"
              ></v-rating>
            </v-col>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="12" class="font-weight-bold">
              Deixe um comentário sobre o produto:
            </v-col>
            <v-col cols="12">
              <v-textarea
                solo
                auto-grow
                hide-details
                class="rounded-card card-shadow avaliacao-textarea"
                v-model="itemAvaliacao.comentario"
                label=""
                rows="3"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemAvaliacao: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.title-oferta-foto {
  min-height: 80px;
}

.avaliacao-textarea {
  max-width: 500px
}
</style>
